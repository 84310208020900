<template>
  <div>
    <gl-title title="结算提现"></gl-title>
    <div style="margin: 18px">
      <el-button
        type="primary"
        size="mini"
        @click="onClickAction('addVisible')"
      >
        新增申请
      </el-button>
      <span style="margin-left: 10px; color: #666"
        >待提现金额: {{ balance }}</span
      >
    </div>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column
          type="index"
          :index="mIndexMethod"
          label="序号"
          align="center"
          width="44"
        ></el-table-column>
        <el-table-column
          prop="idname"
          label="姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="bringMoney"
          label="提现金额"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="accountNumber"
          label="银行卡号"
        ></el-table-column>
        <el-table-column
          prop="openAccountName"
          label="开户名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="bankName"
          label="开户行"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="openAccountAddress"
          label="开户地址"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="approval"
          label="审批状态"
          align="center"
          :formatter="approvalFormatter"
        ></el-table-column>

        <el-table-column
          prop="paymentType"
          label="打款状态"
          align="center"
          :formatter="paymentTypeFormatter"
        ></el-table-column>

        <!-- <el-table-column prop="url" label="发票图片" align="center">
          <template slot-scope="scope">
            <el-image
              style="width: 50px; height: 50px"
              :src="scope.row.pictureUrl"
              fit="cover"
              :preview-src-list="[scope.row.pictureUrl]"
            />
          </template>
        </el-table-column> -->
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button
              type="text"
              @click="onClickAction('previewVisible', row)"
            >
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange"
      >
      </gl-pagination>
    </gl-card>
    <addApply
      v-if="addVisible"
      :itemId="itemId"
      :visible.sync="addVisible"
      @reload="mReload"
    />
    <preview
      :visible.sync="previewVisible"
      :balanceExtractId="balanceExtractId"
    />
  </div>
</template>

<script>
import ListMixin from '@/mixins/list.mixin.js'
import preview from './preview.vue'
import addApply from './addApply.vue'
import { getBalance, getExtractList } from '@/api/balance'
export default {
  components: { preview, addApply },
  mixins: [ListMixin],
  data() {
    return {
      addVisible: false,
      previewVisible: false,
      balanceExtractId: '',
      balance: 0,
    }
  },
  mounted() {
    this.fetcBalance()
    this.mGetListFun = getExtractList
    this.mGetList()
  },
  methods: {
    onClickAction(name, item) {
      if (name === 'previewVisible') {
        this.balanceExtractId = item?.balanceExtractId || ''
      } else {
      }

      this[name] = true
    },
    async fetcBalance() {
      const res = await getBalance()
      this.balance = res.data
    },
    approvalFormatter(row, colum, cellValue) {
      switch (cellValue) {
        case 1:
          return '已通过'
        case 2:
          return '不通过'
        case 3:
          return '待审批'
      }
    },

    paymentTypeFormatter(row, colum, cellValue) {
      switch (cellValue) {
        case 1:
          return '已打款'
        case 2:
          return '待打款'
      }
    },
  },
}
</script>

<style></style>
