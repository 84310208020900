/*
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-04-11 10:50:28
 * @LastEditors: pj
 * @LastEditTime: 2023-04-26 16:08:40
 */
import request from '@/plugins/request'

// 获取待提现金额
export function getBalance (params = {}) {
  return request({
    url: '/api/user/money/getBalance',
    method: 'GET',
    params,
  })
}

// 获取提现列表
export function getSettlementExtractList (data = {}) {
  return request({
    url: '/api/order/parent/getSettlementExtractList',
    method: 'POST',
    data,
  })
}

//提现申请
export function extractApply (data) {
  return request({
    url: '/api/money/balanceExtract/extractApply',
    method: 'POST',
    data,
  })
}

//获取订单结算列表
export function getOrderList (params = {}) {
  return request({
    url: '/api/money/balanceExtractOrder/getOrderList',
    method: 'GET',
    params: params
  })
}

// 获取结算列表
export function getExtractList (data = {}) {
  let user_info = JSON.parse(window.sessionStorage.getItem('user_info'))
  data.userId = user_info.userId
  return request({
    url: '/api/money/balanceExtract/getExtractList',
    method: 'POST',
    data,
  })
}

export default {
  getBalance,
}